import {
  Box,
  Button,
  Checkbox,
  Container,
  Group,
  Modal,
  Notification,
  Space,
  Stack,
  Loader,
  Text,
  TextInput,
} from "@mantine/core";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useForm } from "@mantine/form";

import { useState } from "react";
import { db } from "../../firebase";
import { Check } from "tabler-icons-react";
import { getDoc, doc } from "firebase/firestore";

import {
  createPaypalOrder,
  capturePaypalPayment,
} from "./hooks/paypal-functions";

// Note: Equivalent to a public key. May reside here
const paypalClientID =
  "AZgwK9jtwNgFQAl8UiFveSRPxjjJ_d_4tbYahVZcTCuQcnzJwlr4Y6Cb93Q-5Su6585qhg72AOuvuxq5";

const Pay = ({ companyId }) => {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedCredits, setFetchedCredits] = useState(false);
  const [creditDoc, setCreditDoc] = useState();
  const [creditStatus, setcreditStatus] = useState("select");
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      creditOrder: 20000,
      termsOfService: false,
    },

    validate: {
      creditOrder: (value) => (value >= 1000 ? null : "Enter at least 1000"),
    },
  });

  if (!fetchedCredits) {
    async function fetchCredits() {
      setFetchedCredits(true);
      setLoading(true);

      const docRef = doc(db, "credits", companyId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCreditDoc(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.error("No credit document");
      }
      setLoading(false);

    }
    fetchCredits();
  }

  return (
    <>
      <Text size="lg" weight="semibold">
        Credits
      </Text>
      <Space h="sm" />
      {loading ? (
          <Loader style={{ margin: "1em auto", width: "100%" }}></Loader>
        ) : (
          <Stack style={{ paddingLeft: "2em" }}>
            {!!creditDoc && 
              <>
                <Text size="md" weight="semibold">
                  Current balance: {creditDoc?.creditBalance}
                </Text>
                <Text size="md" weight="semibold">
                  Unprocessed credit deductions: {creditDoc?.unprocessedTransactions.reduce(
                    (accumulator, transaction) => accumulator + transaction.creditQuantity,
                    0,
                  )}
                </Text>
                <Text size="sm" weight="">
                &emsp;Credit deductions are processed daily.
                </Text>
                <Text size="md" weight="semibold">
                  Days remaining (estimate): {Math.trunc(creditDoc?.creditBalance / Math.abs(creditDoc?.averageDailyConsumption))}
                </Text>
                <Text size="md" weight="semibold">
                  Average daily consumption: {Math.trunc(Math.abs(creditDoc?.averageDailyConsumption || 0))}
                </Text>
              </>
            }
            <Group align="start">
              <Button size="md" color="cap-navy" onClick={() => setOpened(true)}>
                Buy more Credits
              </Button>
            </Group>
          </Stack>


        )}
      <Space h="sm" />
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          setcreditStatus("select");
        }}
        title="Credit purchase"
      >
        <Container py="xl">
          {creditStatus === "select" && (
            <Box sx={{ maxWidth: 400 }}>
              <form onSubmit={form.onSubmit(() => setcreditStatus("pay"))}>
                <TextInput
                  required
                  size="md"
                  label="Purchase credits"
                  {...form.getInputProps("creditOrder")}
                />

                <Checkbox
                  mt="md"
                  label="I agree to the terms and conditions."
                  {...form.getInputProps("termsOfService", {
                    type: "checkbox",
                  })}
                />

                <Group position="right" mt="md">
                  <Button
                    size="md"
                    color="cap-navy"
                    type="submit"
                    disabled={!form.values.termsOfService}
                  >
                    Pay $
                    {(
                      Math.ceil(form.values.creditOrder * 0.001 * 100) / 100
                    ).toFixed(2)} 
                    (~R{(
                      Math.ceil(form.values.creditOrder * 0.02 * 100) / 100
                    ).toFixed(2)})
                  </Button>
                </Group>
              </form>
            </Box>
          )}
          {creditStatus === "pay" && (
            <Box sx={{ maxWidth: 400 }}>
              <PayPalScriptProvider
                options={{
                  "client-id": paypalClientID,
                  currency: "USD",
                  intent: "capture",
                }}
              >
                <PayPalButtons
                  style={{
                    color: "blue",
                    shape: "pill",
                  }}
                  createOrder={async () =>
                    (
                      await createPaypalOrder(
                        form.values.creditOrder,
                        companyId
                      )
                    ).id
                  }
                  onApprove={async (data) => {
                    await capturePaypalPayment(data.orderID, companyId).then(() => {
                      setOpened(false);
                      setColor("teal");
                      setTitle("Purchase successful!")
                      setMessage(
                        `${form.values.creditOrder} credits have been added to your account.`
                      );
                      setcreditStatus("complete");
                    }).catch(() => {
                      setOpened(false);
                      setColor("red");
                      setTitle("Transaction error")
                      setMessage(
                        `Could not process the transaction. Please try again later.`
                      );
                    }).finally(() => {
                      setFetchedCredits(false);
                    })
                  }}
                  onError={(error) => {
                    console.log("ERROR!!", error);
                  }}
                />
              </PayPalScriptProvider>
            </Box>
          )}
        </Container>
        {/* Modal content */}
      </Modal>
      {message && (
        <Notification
          icon={<Check size={18} />}
          color={color}
          title={title}
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setMessage(undefined)}
        >
          {message}
        </Notification>
      )}
      {/* {creditStatus === "select" && (
          <>
            <div>
              <label htmlFor="credits">
                Load more credits
              </label>
              <input
                type="number"
                id="credits"
                value={credits}
                min={10}
                step={1}
                onChange={(e) => setcredits(parseInt(e.target.value))}
              />
              <Button color="cap-navy"
                type="button"
                onClick={() => setcreditStatus("pay")}
              >
                {credits > 0
                  ? `Pay $${(Math.ceil(credits * 0.1 * 100) / 100).toFixed(
                      2,
                    )}`
                  : 'Select credits'}
              </button>
            </div>
            <div className="col-12">
            </div>
          </>
      )}
      {creditStatus === "pay" && (
        <>
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientID,
              currency: 'USD',
              intent: 'capture',
            }}
          >
            <PayPalButtons
              style={{
                color: 'blue',
                shape: 'pill',
              }}
              createOrder={async () =>
                (await createPaypalOrder(credits, companyId)).id
              }
              onApprove={async (data) => {
                await capturePaypalPayment(data.orderId, data.companyId);
                setMessage('Thanks for your purchase!');
                setcreditStatus("complete");
              }}
            />
          </PayPalScriptProvider>
          <Button color="cap-navy"
            type="button"
            onClick={() => setcreditStatus("select")}
          >
            Back
          </button>
        </>
      )} */}
    </>
  );
};

export default Pay;
