import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Grid,
  InputWrapper,
  NumberInput,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { Clock } from "tabler-icons-react";

export function DataEntry({
  label,
  value,
  edit,
  inputProps,
  dataType,
  form,
  fieldKey,
}) {
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
    if (dataType === "DATE" && edit) {
      setDate(new Date(value));
      setTime(new Date(value));
    }
  }, [edit, dataType]);

  const handleDateChange = (d, t) => {
    if (dataType === "DATE") {
      if (d) {
        setDate(d);
      }
      if (t) {
        setTime(t);
      }
    }
  };

  useEffect(() => {
    if (date && time) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = new Date(
        date?.getFullYear(),
        date?.getMonth(),
        date?.getDate(),
        time?.getHours(),
        time?.getMinutes()
      );
      form.setFieldValue(
        fieldKey,
        formattedDate.toLocaleDateString("en-GB", options) +
          " " +
          formattedDate.toLocaleTimeString(options)
      );
    }
  }, [date, time]);
  return (
    <>
      <Grid columns={16} gutter={0}>
        <Grid.Col span={8} style={{textAlign: "start", alignContent: "center"}}>
          <Text weight={500}>{label}</Text>
        </Grid.Col>
        <Grid.Col span={8} style={{textAlign: "end", alignContent: "center"}}>
          {!!edit
            ? (() => {
                switch (dataType) {
                  case "NUMBER":
                    return (
                      <NumberInput size="md" {...inputProps} value={value} />
                    );
                  case "STRING":
                    return (
                      <InputWrapper>
                        <TextInput
                          size="md"
                          type="text"
                          {...inputProps}
                        ></TextInput>
                      </InputWrapper>
                    );
                  case "DATE":
                    return (
                      <>
                        <DatePicker
                          // {...inputProps}
                          value={date}
                          onChange={(d) => handleDateChange(d, null)}
                        />{" "}
                        <TimeInput
                          // {...inputProps}
                          icon={<Clock size={18} />}
                          value={time}
                          onChange={(t) => handleDateChange(null, t)}
                        />
                      </>
                    );
                  case "BOOLEAN":
                    return <Checkbox {...inputProps} value={value} />;
                  default:
                    return (
                      <Text style={{ wordWrap: "break-word" }}>{value}</Text>
                    );
                }
              })()
            : (() => {
                switch (dataType) {
                  case "COORDINATE":
                    return (
                      <a
                        href={`https://www.google.com/maps?q=${value.split(", ")[1]},${[value.split(", ")[0]]}&ll=${value.split(", ")[1]},${[value.split(", ")[0]]}&z=14`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Text style={{ wordWrap: "break-word" }}>{value}</Text>
                      </a>
                    );
                  default:
                    return (
                      <Text style={{ wordWrap: "break-word" }}>{value}</Text>
                    );
                }
              })()}
        </Grid.Col>
      </Grid>
    </>
  );
}
