export const TransferConfig = {
  reportConfig: {
    reportId: "voSKAA1lfVm9QybEKUis",
  },
  fieldConfig: {
    deviceCaptured: {
      label: "Device capture date",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
    transactionDate: {
      label: "Date",
      tableRank: 0,
      detailsRank: 0,
      editable: true,
      type: "DATE",
    },
    transactionReviewed: {
      label: "Reviewed",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    },
    litres: {
      label: "Litres",
      tableRank: 5,
      detailsRank: 2,
      editable: true,
      type: "NUMBER",
    },
    tankFromName: {
      label: "Tank from name",
      tableRank: 2,
      detailsRank: 3,
      editable: false,
      type: "STRING",
    },
    tankFromIsMobile: {
      label: "Tank from is mobile",
      tableRank: -1,
      detailsRank: 4,
      editable: false,
      type: "BOOLEAN",
    },
    mobileTankName: {
      label: "Mobile tank name",
      tableRank: 3,
      detailsRank: 6,
      editable: false,
      type: "STRING",
    },
    mobileTankPhoto: {
      label: "Mobile tank photo",
      tableRank: -1,
      detailsRank: 7,
      editable: false,
      type: "IMAGE",
    },
    mobileTankIsMobile: {
      label: "Tank is mobile",
      tableRank: -1,
      detailsRank: 8,
      editable: false,
      type: "BOOLEAN",
    },
    mobileTankSizeLiters: {
      label: "Mobile tank size",
      tableRank: -1,
      detailsRank: 9,
      editable: false,
      type: "NUMBER",
    },
    tankFromFuelType: {
      label: "Mobile tank fuel type",
      tableRank: 7,
      detailsRank: 10,
      editable: false,
      type: "STRING",
    },
    tankFromSizeLiters: {
      label: "Tank from size",
      tableRank: -1,
      detailsRank: 11,
      editable: false,
      type: "NUMBER",
    },
    meterReadingStart: {
      label: "Meter reading start",
      tableRank: -1,
      detailsRank: 12,
      editable: true,
      type: "NUMBER",
    },
    meterReadingStartPhoto: {
      label: "Meter reading start photo",
      tableRank: -1,
      detailsRank: 13,
      editable: false,
      type: "IMAGE",
    },
    meterReadingEnd: {
      label: "Meter reading end",
      tableRank: 6,
      detailsRank: 14,
      editable: true,
      type: "NUMBER",
    },
    meterReadingEndPhoto: {
      label: "Meter reading end photo",
      tableRank: -1,
      detailsRank: 15,
      editable: false,
      type: "IMAGE",
    },
    pumpAttendantSignedImage: {
      label: "Pump attendant signature",
      tableRank: -1,
      detailsRank: 17,
      editable: false,
      type: "IMAGE",
    },
    fuelReceiverSign: {
      label: "Receiver",
      tableRank: -1,
      detailsRank: 18,
      editable: false,
      type: "STRING",
    },
    fuelReceiverSignedImage: {
      label: "Receiver signature",
      tableRank: -1,
      detailsRank: 19,
      editable: false,
      type: "IMAGE",
    },
    createdBy: {
      label: "Creator",
      tableRank: -1,
      detailsRank: 20,
      editable: false,
      type: "STRING",
    },
    gpsLocation: {
      label: "Location",
      tableRank: -1,
      detailsRank: 21,
      editable: false,
      type: "COORDINATE",
    },
    isModified: {
      label: "Data modified",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    },
    companyId: {
      label: "Company ID",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    id: {
      label: "ID",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    syncCompleted: {
      label: "--INTERNAL--",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    },
    receivedOnServer: {
      label: "--INTERNAL--",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
  },
};
