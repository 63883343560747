import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Text,
  Image,
  Loader,
  Badge,
} from "@mantine/core";
import "./app.css";
import {  useSearchParams } from "react-router-dom";
import { auth, applyActionCode } from "../firebase";

export function ConfirmEmail() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    const mode = searchParams.get("mode"); // unused
    const actionCode = searchParams.get("oobCode");
    applyActionCode(auth, actionCode)
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const navigate = useNavigate();

  return (
    <Box className="center" sx={{ maxWidth: 400 }} mx="auto">
      <Stack gap="1">
        <Image
          radius="md"
          src="../../captiva-mark.png"
          style={{ height: "8em", width: "8em", margin: "auto" }}
        />
        {loading ? (
          <>
            <Loader
              style={{ margin: "1em auto", width: "100%" }}
              color="blue"
            ></Loader>
            <Text>Verifying email</Text>
          </>
        ) : (
          <>
            {success ? (
              <>
                <Badge variant="filled" fullWidth color="green" size="xl">
                  Email verified!
                </Badge>
                <Text size="lg">
                  Complete onboarding on the Captiva Fuel App.
                </Text>
                {/iPhone|iPod|Android/.test(navigator.userAgent) && (
                  <>
                    <a href="myapp://com.captiva.fuel">
                      <Button
                        color="cap-navy"
                        size="md"
                        style={{ width: "100%" }}
                      >
                        Continue
                      </Button>
                    </a>
                  </>
                )}
              </>
            ) : (
              <>
                <Badge variant="filled" fullWidth color="red" size="xl">
                  Email not verified
                </Badge>
                <Text size="lg">Please contact us @ admin@captivadata.com</Text>
              </>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}
