export function getWeekOldDate(numOfWeeks, date = new Date()) {
  date.setDate(date.getDate() - numOfWeeks * 7);

  return date;
}

export const isNumber = (value) =>
  /^-?\d+$/.test(value) ? null : "Value not should be a number.";


export const formatValue = (type, value) => {

  const options = {
    year: "2-digit",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };

  switch (type) {
    case "DATE":
      // .seconds from firebase sdk, _seconds from our API
      if(value?.seconds || value?._seconds) {
        return new Date((value?.seconds || value?._seconds) * 1000).toLocaleDateString('en-GB', options) || "Unknown";
      } else {
        return new Date(value).toLocaleDateString('en-GB', options) || "Unknown";
      }
    case "COORDINATE":
      return value?.join(", ");
    case "NUMBER":
      return +value ?? "No Value";
    default:
      return value?.toString() ?? "No value";
  }
};
