import React from "react";
import { Button, Card, Divider, Group, Modal, Stack, Text } from "@mantine/core";
import { deleteUser } from "../shared/hooks/user-management";
import { logout } from "../../firebase";
import { useState } from "react";

export function Account({ auth }) {
  const [opened, setOpened] = useState(false);

  return (
    <Card withBorder>
        <Text size="xl" weight="semibold">
          Account
        </Text>
        <Text size="sm" weight="italic">
          Your account settings
        </Text>
        <Divider my="sm" />
        <Stack>
        <div>
          Email: {auth?.claims?.email}
        </div>
        <div>
          Admin: {auth?.claims?.admin ? "True": "False"}
        </div>
        <div>
          CompanyId: {auth?.claims?.companyId}
        </div>
        <div>
          UID: {auth?.claims?.user_id}
        </div>
        <Button size="md" color="red" onClick={() => setOpened(true)}>
          Delete account
        </Button>
      </Stack>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Are you sure?"
      >
        <Text size="sm" weight="italic">
          Account deletion cannot be undone.
        </Text>
        <Divider my="lg" />
        <Group>
          <Button  size="md" color="red" onClick={async() => {
            await deleteUser();
            setOpened(false);
            logout();
          }}>Delete account</Button>
          <Button  size="md" color="cap-blue" onClick={() => setOpened(false)}>Cancel</Button>
        </Group>
      </Modal>
    </Card>
  );
}

export default Account;
