export const BulkReceiptConfig = {
  reportConfig: {
    reportId: "E8qqqM2UgsBQ3iQ7T88E",
  },
  fieldConfig: {
    companyId: {
      label: "CompanyId",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    createdBy: {
      label: "Creator",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    deliveryRegistrationNumber: {
      label: "Delivery registration",
      tableRank: -1,
      detailsRank: 1,
      editable: false,
      type: "STRING",
    },
    deliveryRegistrationNumberPhoto: {
      label: "Delivery registration photo",
      tableRank: -1,
      detailsRank: 2,
      editable: false,
      type: "IMAGE",
    },
    deviceCaptured: {
      label: "Device captured date",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
    dipReadingAfter: {
      label: "Dip reading after",
      tableRank: -1,
      detailsRank: 6,
      editable: true,
      type: "NUMBER",
    },
    dipReadingAfterPhoto: {
      label: "Dip reading after photo",
      tableRank: -1,
      detailsRank: 7,
      editable: false,
      type: "IMAGE",
    },
    dipReadingBefore: {
      label: "Dip reading before",
      tableRank: -1,
      detailsRank: 8,
      editable: true,
      type: "NUMBER",
    },
    dipReadingBeforePhoto: {
      label: "Dip reading before photo",
      tableRank: -1,
      detailsRank: 9,
      editable: false,
      type: "IMAGE",
    },
    documentNumber: {
      label: "Document number",
      tableRank: -1,
      detailsRank: 10,
      editable: false,
      type: "STRING",
    },
    gpsLocation: {
      label: "Location",
      tableRank: -1,
      detailsRank: 12,
      editable: false,
      type: "COORDINATE",
    },
    isModified: {
      label: "Entry modified",
      tableRank: -1,
      detailsRank: 13,
      editable: false,
      type: "BOOLEAN",
    },
    litersReceived: {
      label: "Litres",
      tableRank: 2,
      detailsRank: 14,
      editable: true,
      type: "NUMBER",
    },
    pumpAttendantSignature: {
      label: "Pump attendant signed",
      tableRank: -1,
      detailsRank: 15,
      editable: false,
      type: "STRING",
    },
    pumpAttendantSignatureImage: {
      label: "Pump attendant signature",
      tableRank: -1,
      detailsRank: 16,
      editable: false,
      type: "IMAGE",
    },
    supplierDeliveryNoteNumberPhoto: {
      label: "Receipt delivery note photo",
      tableRank: -1,
      detailsRank: 17,
      editable: false,
      type: "IMAGE",
    },
    receivedOnServer: {
      label: "xx",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    supplier: {
      label: "Supplier",
      tableRank: 1,
      detailsRank: 18,
      editable: false,
      type: "STRING",
    },
    supplierDeliveryNoteNumber: {
      label: "Supplier delivery note",
      tableRank: -1,
      detailsRank: 19,
      editable: true,
      type: "STRING",
    },
    supplierDriverName: {
      label: "Supplier driver name",
      tableRank: -1,
      detailsRank: 21,
      editable: true,
      type: "STRING",
    },
    supplierPumpReadingAfter: {
      label: "Supplier pump reading after",
      tableRank: -1,
      detailsRank: 22,
      editable: true,
      type: "STRING",
    },
    supplierPumpReadingAfterPhoto: {
      label: "Supplier pump reading after photo",
      tableRank: -1,
      detailsRank: 23,
      editable: false,
      type: "IMAGE",
    },
    supplierPumpReadingBefore: {
      label: "Supplier pump reading before",
      tableRank: -1,
      detailsRank: 24,
      editable: true,
      type: "STRING",
    },
    supplierPumpReadingBeforePhoto: {
      label: "Supplier pump reading before photo",
      tableRank: -1,
      detailsRank: 25,
      editable: false,
      type: "IMAGE",
    },
    supplierSignedImage: {
      label: "Supplier signature image",
      tableRank: -1,
      detailsRank: 27,
      editable: false,
      type: "IMAGE",
    },
    syncCompleted: {
      label: "xx",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    tankFuelType: {
      label: "Fuel type",
      tableRank: -3,
      detailsRank: 29,
      editable: false,
      type: "STRING",
    },
    tankIsMobile: {
      label: "Tank is mobile",
      tableRank: -1,
      detailsRank: 30,
      editable: false,
      type: "STRING",
    },
    tankName: {
      label: "Tank name",
      tableRank: 4,
      detailsRank: 31,
      editable: false,
      type: "STRING",
    },
    tankSealPhotos: {
      label: "Tank seal photo",
      tableRank: -1,
      detailsRank: 32,
      editable: false,
      type: "IMAGE",
    },
    tankSizeLiters: {
      label: "Tank size",
      tableRank: -1,
      detailsRank: 33,
      editable: false,
      type: "NUMBER",
    },
    transactionDate: {
      label: "Date",
      tableRank: 0,
      detailsRank: 0,
      editable: true,
      type: "DATE",
    },
    waterInTank: {
      label: "Water in tank",
      tableRank: -1,
      detailsRank: 40,
      editable: false,
      type: "BOOLEAN",
    },
    id: {
      label: "ID",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    transactionReviewed: {
      label: "Reviewed",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    }
  },
};
