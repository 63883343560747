import React, { useState, useEffect } from "react";

import {
  Button,
  Divider,
  Grid,
  Image,
  Modal,
  Notification,
  Space,
  Table,
  Tabs,
  Text,
} from "@mantine/core";

import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";

import { InfoCircle } from "tabler-icons-react";
import { QrDisplay } from "../components/shared/qr-display";

export function Assets({ companyId }) {
  const [message, setMessage] = useState("");
  const [tanks, setTanks] = useState([]);
  const [fetchedTanks, setFetchedTanks] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [fetchedSuppliers, setFetchedSuppliers] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [fetchedVehicles, setFetchedVehicles] = useState(true);
  const [vehicleGroups, setVehicleGroups] = useState([]);
  const [fetchedVehicleGroups, setFetchedVehicleGroups] = useState(true);
  const [operators, setOperators] = useState([]);
  const [fetchedOperators, setFetchedOperators] = useState(true);
  const [activities, setActivities] = useState([]);
  const [fetchedActivities, setFetchedActivities] = useState(true);
  const [products, setProducts] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState(true);
  const [zones, setZones] = useState([]);
  const [fetchedZones, setFetchedZones] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const onChange = (active, tabKey) => {
    setActiveTab(active);
    if (tabKey === "tanks" && !tanks?.length) {
      setFetchedTanks(false);
    } else if (tabKey === "suppliers" && !suppliers?.length) {
      setFetchedSuppliers(false);
    } else if (tabKey === "vehicles" && !vehicles?.length) {
      setFetchedVehicles(false);
    } else if (tabKey === "vehicleGroups" && !vehicleGroups?.length) {
      setFetchedVehicleGroups(false);
    } else if (tabKey === "vehicleOperators" && !operators?.length) {
      setFetchedOperators(false);
    } else if (tabKey === "activities" && !activities?.length) {
      setFetchedActivities(false);
    } else if (tabKey === "products" && !products?.length) {
      setFetchedProducts(false);
    } else if (tabKey === "zones" && !zones?.length) {
      setFetchedZones(false);
    }
  };

  const handleResults = (documentSnapshots, setter) => {
    let entries = [];
    documentSnapshots.forEach((doc) =>
      entries.push({ id: doc.id, ...doc.data() })
    );
    setter(entries);
  };

  const buildQuery = (templateName) => {
    return query(
      collection(db, templateName),
      where("companyId", "==", companyId)
    );
  };

  useEffect(() => {
    if (!fetchedTanks) {
      async function fetchTanks() {
        setFetchedTanks(true);
        const documentSnapshots = await getDocs(buildQuery("tanks"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("tanks");
        }
        handleResults(documentSnapshots, setTanks);
      }
      fetchTanks();
    }
    if (!fetchedSuppliers) {
      async function fetchSuppliers() {
        setFetchedSuppliers(true);
        const documentSnapshots = await getDocs(buildQuery("suppliers"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("suppliers");
        }
        handleResults(documentSnapshots, setSuppliers);
      }
      fetchSuppliers();
    }
    if (!fetchedVehicles) {
      async function fetchVehicles() {
        setFetchedVehicles(true);
        const documentSnapshots = await getDocs(buildQuery("fleet"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("vehicles");
        }
        handleResults(documentSnapshots, setVehicles);
      }
      fetchVehicles();
    }
    if (!fetchedVehicleGroups) {
      async function fetchVehicleGroups() {
        setFetchedVehicleGroups(true);
        const documentSnapshots = await getDocs(buildQuery("fleetGroups"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("vehicle groups");
        }
        handleResults(documentSnapshots, setVehicleGroups);
      }
      fetchVehicleGroups();
    }
    if (!fetchedOperators) {
      async function fetchOperators() {
        setFetchedOperators(true);
        const documentSnapshots = await getDocs(buildQuery("fleetOperators"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("vehicle operators");
        }
        handleResults(documentSnapshots, setOperators);
      }
      fetchOperators();
    }
    if (!fetchedActivities) {
      async function fetchActivities() {
        setFetchedActivities(true);
        const documentSnapshots = await getDocs(buildQuery("activities"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("activities");
        }
        handleResults(documentSnapshots, setActivities);
      }
      fetchActivities();
    }
    if (!fetchedProducts) {
      async function fetchProducts() {
        setFetchedProducts(true);
        const documentSnapshots = await getDocs(buildQuery("products"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("products");
        }
        handleResults(documentSnapshots, setProducts);
      }
      fetchProducts();
    }
    if (!fetchedZones) {
      async function fetchZones() {
        setFetchedZones(true);
        const documentSnapshots = await getDocs(buildQuery("areaForFleetUse"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("zones");
        }
        handleResults(documentSnapshots, setZones);
      }
      fetchZones();
    }
  }, [
    fetchedTanks,
    fetchedSuppliers,
    fetchedVehicles,
    fetchedVehicleGroups,
    fetchedOperators,
    fetchedActivities,
    fetchedProducts,
    fetchedZones,
  ]);

  const setAssetMessage = (asset) => {
    setMessage(
      `No ${asset} configured. Open the mobile application and go to 'Settings' to configure Company assets.`
    );
  };

  useEffect(() => {
    if (companyId) setFetchedTanks(false);
  }, [companyId]);

  return (
    <>
      {companyId && (
        <>
          <Text size="xl" weight="semibold">
            Assets
          </Text>
          <Space h="sm" />
          <Divider />
          <Space h="sm" />
          <Tabs active={activeTab} onTabChange={onChange}>
            <Tabs.Tab label="Tanks" tabKey="anks">
              {tanks && tanks?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={24}>
                    <TankTable elements={tanks} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Vehicles" tabKey="vehicles">
              {vehicles && vehicles?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <VehicleTable elements={vehicles} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Suppliers" tabKey="suppliers">
              {suppliers && suppliers?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <SupplierTable elements={suppliers} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Vehicle groups" tabKey="vehicleGroups">
              {vehicleGroups && vehicleGroups?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <VehicleGroupsTable elements={vehicleGroups} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Vehicle operators" tabKey="vehicleOperators">
              {operators && operators?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <VehicleOperatorsTable elements={operators} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Activities" tabKey="activities">
              {activities && activities?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <ActivitiesTable elements={activities} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Products" tabKey="products">
              {products && products?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <ProductsTable elements={products} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab label="Zones" tabKey="zones">
              {zones && zones?.length ? (
                <Grid columns={24}>
                  <Grid.Col span={11}>
                    <ZonesTable elements={zones} />
                  </Grid.Col>
                </Grid>
              ) : null}
            </Tabs.Tab>
          </Tabs>
          {message && (
            <Notification
              icon={<InfoCircle size={18} />}
              color="cap-blue"
              title="Info"
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                zIndex: 1000,
              }}
              onClose={() => setMessage("")}
            >
              {message}
            </Notification>
          )}
        </>
      )}
    </>
  );
}

function TankTable({ elements }) {
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.size}</td>
      <td>{element.mobileTank.toString()}</td>
      <td>{element.isActive.toString()}</td>
      <td>{element.fuelType}</td>
      <td>
        <Button
          color={"gray"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={
            <Image
              src="../../qr.svg"
              size={16}
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Print
        </Button>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Tank name</th>
          <th>Size (litres)</th>
          <th>Mobility</th>
          <th>Active</th>
          <th>Fuel type</th>
          <th>Asset QR</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <Modal
        opened={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeOnClickOutside
      >
        <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"TANK"} />
      </Modal>
    </Table>
  );
}

function SupplierTable({ elements }) {
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.isActive.toString()}</td>
      <td>
        <Button
          color={"gray"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={
            <Image
              src="../../qr.svg"
              size={16}
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Print
        </Button>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Active</th>
          <th>Asset QR</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <Modal
        opened={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeOnClickOutside
      >
        <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"SUPPLIER"} />
      </Modal>
    </Table>
  );
}

function VehicleTable({ elements }) {
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.registration}</td>
      <td>{element.description}</td>
      <td>{element.isActive.toString()}</td>
      <td>
        <Button
          color={"gray"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={
            <Image
              src="../../qr.svg"
              size={16}
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Print
        </Button>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Registration</th>
          <th>Description</th>
          <th>Active</th>
          <th>Asset QR</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <Modal
        opened={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeOnClickOutside
      >
        <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"VEHICLE"} />
      </Modal>
    </Table>
  );
}

function VehicleGroupsTable({ elements }) {
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.description}</td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

function VehicleOperatorsTable({ elements }) {
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.fullName}</td>
      <td>{element.employeeCode}</td>
      <td>
        <Button
          color={"gray"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={
            <Image
              src="../../qr.svg"
              size={16}
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Print
        </Button>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Employee code</th>
          <th>Asset QR</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <Modal
        opened={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeOnClickOutside
      >
        <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"OPERATOR"} />
      </Modal>
    </Table>
  );
}

function ActivitiesTable({ elements }) {
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.rebateEligible.toString()}</td>
      <td>
        <Button
          color={"gray"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={
            <Image
              src="../../qr.svg"
              size={16}
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Print
        </Button>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Rebate eligible</th>
          <th>Asset QR</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <Modal
        opened={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeOnClickOutside
      >
        <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"ACTIVITY"} />
      </Modal>
    </Table>
  );
}

function ProductsTable({ elements }) {
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.isActive.toString()}</td>
      <td>
        <Button
          color={"gray"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={
            <Image
              src="../../qr.svg"
              size={16}
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Print
        </Button>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Active</th>
          <th>Asset QR</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <Modal
        opened={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeOnClickOutside
      >
        <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"PRODUCTS"} />
      </Modal>
    </Table>
  );
}
function ZonesTable({ elements }) {
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.zoneName}</td>
      <td>{element.sections?.length}</td>
      <td>{element.isActive.toString()}</td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Sections</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
