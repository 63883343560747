import React, { useState, useEffect } from "react";

import {
  Button,
  Divider,
  Grid,
  Group,
  Notification,
  Space,
  Table,
  Loader,
  Text,
} from "@mantine/core";

import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";

import Pay from "../components/shared/pay";
import {
  downgradeToUser,
  upgradeToAdmin,
} from "../components/shared/hooks/user-management";
import { Check, Flag, InfoCircle } from "tabler-icons-react";
import BillingInfo from "../components/shared/billing-info";

function UserTable({ elements, updateCallback, userId }) {
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleAdminPromotion = (uid, name) => {
    upgradeToAdmin(uid)
      .then(() => {
        setMessage(
          `Successfully promoted ${name} to 'Admin'. It may take up to 60 minutes for permissions to be propogated`
        );
        updateCallback();
      })
      .catch(() => {
        setErrorMessage("Promotion failed. Please try again.");
      });
  };

  const handleUserDemotion = (uid, name) => {
    downgradeToUser(uid)
      .then(() => {
        setMessage(
          `Successfully demoted ${name} to 'User'. It may take up to 60 minutes for permissions to be propogated`
        );
        updateCallback();
      })
      .catch(() => {
        setErrorMessage("Promotion failed. Please try again.");
      });
  };
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element?.firstName}</td>
      <td>{element?.lastName}</td>
      <td>{element?.admin?.toString()}</td>
      <td>
        {!element.admin && userId !== element.uid && (
          <Button
            size="sm"
            color="cap-navy"
            onClick={() => {
              handleAdminPromotion(element.uid, element.firstName);
            }}
          >
            Make Admin
          </Button>
        )}
        {element.admin && userId !== element.uid && (
          <Button
            color="cap-navy"
            size="sm"
            onClick={() => {
              handleUserDemotion(element.uid, element.firstName);
            }}
          >
            Remove Admin
          </Button>
        )}
      </td>
    </tr>
  ));

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>First name</th>
            <th>Last name</th>
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {message && (
        <Notification
          icon={<Check size={18} />}
          color="teal"
          title="Success"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setMessage("")}
        >
          {message}
        </Notification>
      )}
      {errorMessage && (
        <Notification
          icon={<Flag size={18} />}
          color="red"
          title="Error"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setErrorMessage("")}
        >
          {errorMessage}
        </Notification>
      )}
    </>
  );
}

export function CompanyDetails({ companyId, userId }) {
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(true);

  const handleResults = (documentSnapshots, setter) => {
    let entries = [];

    documentSnapshots.forEach((doc) =>
      entries.push({ id: doc.id, ...doc.data() })
    );

    setter(entries);
  };

  const buildQuery = (templateName) => {
    return query(
      collection(db, templateName),
      where("companyId", "==", companyId)
    );
  };

  useEffect(() => {
    if (!fetchedUsers) {
      async function fetchUsers() {
        setFetchedUsers(true);
        setLoading(true);

        const documentSnapshots = await getDocs(buildQuery("users"));

        handleResults(documentSnapshots, setUsers);
        setLoading(false);
      }
      fetchUsers();
    }
  });

  return (
    <>
      {companyId && (
        <>
          <Text size="xl" weight="semibold">
            Company
          </Text>
          <Text size="sm" weight="italic">
            Employee and credit status
          </Text>
          <Divider my="sm" />
          <Pay companyId={companyId}></Pay>
          <Divider my="sm" />
          <BillingInfo companyId={companyId}></BillingInfo>
          <Divider my="sm" />
          <Text size="lg" weight="semibold">
            Users
          </Text>
          {!users?.length ? (
            <Group style={{ padding: "1em 2em" }}>
              <Button
                size="md"
                color="cap-navy"
                onClick={() => setFetchedUsers(false)}
              >
                Load Users
              </Button>
            </Group>
          ) : null}
          <Grid columns={24}>
            {loading ? (
              <Loader style={{ margin: "auto", width: "100%" }}></Loader>
            ) : (
              <>
                {users && users?.length ? (
                  <Grid.Col span={24} offset={1}>
                    <Space h="md" />
                    <UserTable
                      elements={users}
                      updateCallback={() => setFetchedUsers(false)}
                      userId={userId}
                    />
                  </Grid.Col>
                ) : null}
              </>
            )}
            <Divider my="sm" />
          </Grid>
          {message && (
            <Notification
              icon={<InfoCircle size={18} />}
              color="cap-blue"
              title="Info"
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                zIndex: 1000,
              }}
              onClose={() => setMessage("")}
            >
              {message}
            </Notification>
          )}
        </>
      )}
    </>
  );
}
