import React, { useState, useEffect } from "react";
import { Box, Button, Center, Divider, Grid, Loader, Select, Text } from "@mantine/core";
import { Link } from "react-router-dom";

import { DateRangePicker } from "@mantine/dates";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";
import { getWeekOldDate } from "../components/listings/hooks";
import { fetchAndDownloadReport } from "../components/shared/hooks/generate-report";
import DownloadAlert from "../components/shared/downloadAlert";

export function Reports({companyId}) {
  const [reportConfigs, setReportConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedCredits, setFetchedCredits] = useState(false);
  const [creditDoc, setCreditDoc] = useState();
  const [reportConfigsFetched, setReportConfigsFetched] = useState(false);
  const [selectedDateRange, setDates] = useState([
    getWeekOldDate(1),
    new Date(),
  ]);
  const [renderType, setRendertType] = useState();
  const [reportName, setReportName] = useState();
  const [Base64File, setBase64File] = useState();
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const [selectedReportId, setSelectedReportId] = useState();

  useEffect(() => {
    if (!fetchedCredits) {
      async function fetchCredits() {
        setFetchedCredits(true);
  
        const docRef = doc(db, "credits", companyId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          setCreditDoc(docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          console.error("No credit document");
        }
      }
      fetchCredits();
    }
  }, [])

  const buildQuery = (templateName) => {
    return query(
      collection(db, templateName),
      where("isActive", "==", true),
      where("isDateRanged", "==", true),
      where("isAdvancedReport", "==", false)
    );
  };

  useEffect(() => {
    const fetchConfigs = async () => {
      const documentSnapshots = await getDocs(buildQuery("reportSetUp"));

      handleResults(documentSnapshots, setReportConfigs);
    };

    const handleResults = (documentSnapshots, setter) => {
      let entries = [];

      documentSnapshots.forEach((doc) =>
        entries.push({ id: doc.id, ...doc.data() })
      );

      entries.sort((a, b) => {
        if (a.reportShortName < b.reportShortName) {
          return -1;
        }
        if (a.reportShortName > b.reportShortName) {
          return 1;
        }
        return 0;
      });

      setter(entries);
    };

    try {
      if (!reportConfigsFetched) {
        setReportConfigsFetched(true);
        fetchConfigs();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleGenerateReport = async (reportId, dateRange) => {
    const startDate = dateRange[0].toISOString().split("T")[0];
    const endDate = dateRange[1].toISOString().split("T")[0];
    const reportToGenerate = reportConfigs.find((r) => r.id === reportId);

    const reportRenderType = reportToGenerate.renderingType;
    const reportName = reportToGenerate.reportShortName + `-${new Date().toISOString().split('T')[0]}`;

    setLoading(true);

    const result = await fetchAndDownloadReport(
      reportId,
      startDate,
      endDate,
      reportRenderType,
      reportName,
      companyId
    );

    setRendertType(reportRenderType);
    setReportName(reportName);
    setBase64File(result);
    setDownloadModalOpen(true)

    setLoading(false);
  };

  return (
    <>
      <Text size="lg" weight="semibold">
        Reports
      </Text>
      <Text size="sm" weight="italic">
        Date ranged
      </Text>
      <Divider my="sm" />
      <Box style={{ maxWidth: "500px", marginTop: "1em", margin: "auto" }}>
        <Select
          label="Choose a report to generate"
          placeholder="Report name"
          value={selectedReportId}
          onChange={setSelectedReportId}
          data={reportConfigs.map((config) => {
            return { value: config.id, label: config.reportShortName };
          })}
        />
        <DateRangePicker
          label="Choose a date range"
          placeholder="Report date range"
          amountOfMonths={1}
          style={{ marginTop: "1em" }}
          clearable={false}
          value={selectedDateRange}
          onChange={setDates}
        />
        {loading ? (
          <Loader style={{ margin: "1em auto", width: "100%" }}></Loader>
        ) : (
          <>
            <Button
              color="cap-navy"
              size="md"
              style={{ margin: "2em 25%", width: "50%" }}
              disabled={!selectedDateRange || !selectedReportId || creditDoc?.creditBalance <= 0}
              onClick={() =>
                handleGenerateReport(selectedReportId, selectedDateRange)
              }
            >
              Generate report
            </Button>
            {(creditDoc?.creditBalance <= 0) && 
              (
                <>
                  <Grid gutter="sm" style={{border: '1px solid #EAE0DA', borderRadius:'3px'}}>
                    <Grid.Col span={8}><Center style={{height:'100%'}}>Credit balance too low to generate reports.</Center></Grid.Col>
                    <Grid.Col span={4}><Link to="/dashboard/settings"><Button color="cap-orange">Purchase Credits</Button></Link></Grid.Col>
                  </Grid>
                </>
              )
            }
          </>
        )}
      </Box>
      <DownloadAlert renderType={renderType} reportName={reportName} Base64File={Base64File} modalOpen={downloadModalOpen} onModalClose={() => setDownloadModalOpen(false)}></DownloadAlert>
    </>
  );
}
