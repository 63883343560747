import React, { useState, useEffect } from "react";

import {
  Button,
  Group,
  Notification,
  Space,
  Stack,
  Modal,
  Container,
  Box,
  Loader,
  Text,
  TextInput,
  Input,
} from "@mantine/core";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useForm } from "@mantine/form";

import { Check, Flag } from "tabler-icons-react";
import { db } from "../../firebase";

const BillingInfo = ({ companyId }) => {
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedBillingInfo, setFetchedBillingInfo] = useState(true);
  const [billingDoc, setBillingDoc] = useState();
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      billingEmails: [],
      countryOfResidence: "",
      addressLine1: "",
      billingName: "",
      taxNumber: "",
      addressStateProvince: "",
      addressPostalCode: "",
      companyRegistrationNumber: "",
      addressCity: "",
      addressLine2: "",
    },

    //   validate: {
    //     creditOrder: (value) => (value >= 100 ? null : "Enter at least 100"),
    //   },
  });

  const addInput = () => {
    emailList.push("");
    setEmailList([...emailList]);
  };

  useEffect(() => {
    if (!fetchedBillingInfo) {
      async function fetchBillingInfo() {
        setFetchedBillingInfo(true);
        setLoading(true);
        const reference = doc(db, 'billingInfo', companyId)
        const documentSnapshot = await getDoc(reference)
        if(documentSnapshot.exists) {
          const billingInfoDoc = documentSnapshot.data();
          // console.log(billingInfoDoc);
          setBillingDoc(billingInfoDoc);
          Object.keys(billingInfoDoc).forEach((key) => {
            form.setFieldValue(key, billingInfoDoc[key]);
          });
          setEmailList(billingInfoDoc.billingEmails);
        } else {
          alert(
            "No billing information found. Please contact a CaptivaData representative."
          );
        }
        setLoading(false);
      }
      fetchBillingInfo();
    }
  });

  const saveConfig = async () => {
    setLoading(true);
    const payload = {
      billingEmails: emailList,
      billingName: form.values.billingName,
      addressLine1: form.values.addressLine1,
      addressLine2: form.values.addressLine2,
      addressCity: form.values.addressCity,
      addressStateProvince: form.values.addressStateProvince,
      addressPostalCode: form.values.addressPostalCode,
      countryOfResidence: form.values.countryOfResidence,
      companyRegistrationNumber: form.values.companyRegistrationNumber,
      taxNumber: form.values.taxNumber,
      registeredTax: !!form.values.companyRegistrationNumber && !!form.values.taxNumber
    };
    const reference = doc(db, 'billingInfo', companyId)

    await updateDoc(reference, payload).then(() => {
      // reload
      setFetchedBillingInfo(false)
      setMessage("Configuration successfully updated.")
    }).catch(e => {
      setErrorMessage('Could not save notification config.')
    }).finally(() => {
      setOpened(false);
    })
    setLoading(false);
  }

  return (
    <>
      <Text size="lg" weight="semibold">
        Billing Information
      </Text>
      {!billingDoc && 
        <Group style={{ padding: "1em 2em" }}>
          <Button
            size="md"
            color="cap-navy"
            onClick={() => setFetchedBillingInfo(false)}
          >
            Load Billing Information
          </Button>
        </Group>
      }
      <Space h="sm" />
      {loading ? (
        <Loader style={{ margin: "1em auto", width: "100%" }}></Loader>
      ) : (
        <Stack style={{ paddingLeft: "2em" }}>
          {!!billingDoc && (
            <>
              <Text size="md" weight="semibold">
                Company name: {billingDoc?.billingName}
              </Text>
              <Text size="md" weight="semibold">
                Address line 1: {billingDoc?.addressLine1}
              </Text>
              <Text size="md" weight="semibold">
                Address line 2: {billingDoc?.addressLine2}
              </Text>
              <Text size="md" weight="semibold">
                City: {billingDoc?.addressCity}
              </Text>
              <Text size="md" weight="semibold">
                State / Province: {billingDoc?.addressStateProvince}
              </Text>
              <Text size="md" weight="semibold">
                Postal code: {billingDoc?.addressPostalCode}
              </Text>
              <Text size="md" weight="semibold">
                Country: {billingDoc?.countryOfResidence}
              </Text>
              <Text size="md" weight="semibold">
                Company registration number:{" "}
                {billingDoc?.companyRegistrationNumber}
              </Text>
              <Text size="md" weight="semibold">
                Company tax number: {billingDoc?.taxNumber}
              </Text>
              <Text size="md" weight="semibold">
                Company ID: {billingDoc?.companyId}
              </Text>
              <Text size="md" weight="semibold">
                Billing emails: <br /> {billingDoc?.billingEmails.join(", ")}
              </Text>
              <Group align="start">
                <Button
                  size="md"
                  color="cap-navy"
                  onClick={() => setOpened(true)}
                >
                  Edit
                </Button>
              </Group>
            </>
          )}
        </Stack>
      )}
      <Space h="sm" />
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title="Billing Information"
      >
        <Container py="xl">
          <Box sx={{ maxWidth: 400 }}>
            <form onSubmit={form.onSubmit(saveConfig)}>
              <TextInput
                required
                size="md"
                label="Company name (billing)"
                {...form.getInputProps("billingName")}
              />
              <TextInput
                required
                size="md"
                label="Address line 1"
                {...form.getInputProps("addressLine1")}
              />
              <TextInput
                size="md"
                label="Address line 2"
                {...form.getInputProps("addressLine2")}
              />
              <TextInput
                required
                size="md"
                label="City"
                {...form.getInputProps("addressCity")}
              />
              <TextInput
                required
                size="md"
                label="State / Province"
                {...form.getInputProps("addressStateProvince")}
              />
              <TextInput
                required
                size="md"
                label="Postal code"
                {...form.getInputProps("addressPostalCode")}
              />
              <TextInput
                required
                size="md"
                label="Country"
                {...form.getInputProps("countryOfResidence")}
              />
              <TextInput
                required
                size="md"
                label="Company tax number"
                {...form.getInputProps("taxNumber")}
              />
              <TextInput
                required
                size="md"
                label="Company registration number"
                {...form.getInputProps("companyRegistrationNumber")}
              />
              <Text size="lg">Billing emails</Text>
              {emailList.map((email, i) => (
                <Input
                  key={i}
                  value={email}
                  size="md"
                  onChange={(e) => {
                    emailList[i] = e.target.value;
                    setEmailList([...emailList]);
                  }}
                />
              ))}
              <Button
                color="cap-navy"
                onClick={addInput}
                size="md"
                style={{ maxWidth: "50%", minWidth: "25%" }}
              >
                Add email
              </Button>
              <Group position="right" mt="md">
                <Button
                  size="md"
                  color="cap-navy"
                  type="submit"
                  // disabled={!form.values.termsOfService}
                >
                  Save
                </Button>
              </Group>
            </form>
          </Box>
        </Container>
      </Modal>
      {message && (
        <Notification
          icon={<Check size={18} />}
          color="teal"
          title="Success"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setMessage("")}
        >
          {message}
        </Notification>
      )}
      {errorMessage && (
        <Notification
          icon={<Flag size={18} />}
          color="red"
          title="Error"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setErrorMessage("")}
        >
          {errorMessage}
        </Notification>
      )}
    </>
  );
};

export default BillingInfo;
