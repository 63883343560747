import React, { useState, useEffect } from "react";

import {
  Text,
  MultiSelect,
  Stack,
  Input,
  Button,
  Loader,
  Divider,
  Notification
} from "@mantine/core";

import { collection, getDoc, getDocs, query, where, doc, updateDoc } from "firebase/firestore";

import { db } from "../firebase";
import { Check, Flag } from "tabler-icons-react";

export function NotificationConfiguration({companyId}) {
    const [notificationConfigs, setNotificationConfigs] = useState({emails: []});
    const [loading, setLoading] = useState(false);
    const [emailList, setEmailList] = useState([]);
    const [notificationConfigFetched, setNotificationConfigFetched] = useState(false);
    const [captureValue, setCaptureValue] = useState([]);
    const [dailyValue, setDailyValue] = useState([]);
    const [weeklyValue, setWeeklyValue] = useState([]);
    const [monthlyValue, setMonthlyValue] = useState([]);
    const [reportConfigs, setReportConfigs] = useState([]);
    const [reportConfigsFetched, setReportConfigsFetched] = useState(false);
    const [nonRangedReportConfigs, setNonRangedReportConfigs] = useState([]);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const sortByName = (entries) => {
      entries.sort((a, b) => {
        if (a.reportShortName < b.reportShortName) {
          return -1;
        }
        if (a.reportShortName > b.reportShortName) {
          return 1;
        }
        return 0;
      });
    }

    // fetch current configurations
    useEffect(() => {
      const fetchConfig = async () => {
        setLoading(true);
        const reference = doc(db, 'reportNotifications', companyId)
        const notificationSnapshot = await getDoc(reference)
        if(notificationSnapshot.exists) {
            setNotificationConfigs(notificationSnapshot.data());
            setLoading(false);
        } else {
          alert(
            "No notification information found. Please contact a CaptivaData representative."
          );
        }
      };

      try {
        if (!notificationConfigFetched && companyId) {
          setNotificationConfigFetched(true);
          fetchConfig();
        }
      } catch (error) {
        console.log(error);
      }
    }, [companyId, notificationConfigFetched]);
 
    // fetch report config options
    useEffect(() => {
        const buildQuery = (templateName) => {
            return query(
            collection(db, templateName),
            where("isActive", "==", true),
            where("isDateRanged", "==", true)
            );
        };
        const buildQueryOnCapture = (templateName) => {
            return query(
            collection(db, templateName),
            where("isActive", "==", true),
            where("isDateRanged", "==", false)
            );
    };
      const fetchConfigs = async () => {
        const documentSnapshots = await getDocs(buildQuery("reportSetUp"));
        handleResults(documentSnapshots, setReportConfigs);
      };
      const fetchConfigOnCapture = async () => {
        const documentSnapshots = await getDocs(buildQueryOnCapture("reportSetUp"));
        handleResults(documentSnapshots, setNonRangedReportConfigs);
      };
      const handleResults = (documentSnapshots, setter) => {
        let entries = [];
        documentSnapshots.forEach((doc) => {
          if(doc.get('reportTemplateLink')) {
            entries.push({ id: doc.id, ...doc.data() })
          }
        });
        sortByName(entries);
        setter(entries);
      };
      try {
        if (!reportConfigsFetched) {
          setReportConfigsFetched(true);
          fetchConfigs();
          fetchConfigOnCapture();
        }
      } catch (error) {
        console.log(error);
      }
    }, [reportConfigsFetched]);

    useEffect(() => {
        setEmailList(notificationConfigs.emails);
        setCaptureValue(notificationConfigs.onCaptured);
        setDailyValue(notificationConfigs.daily);
        setWeeklyValue(notificationConfigs.weekly);
        setMonthlyValue(notificationConfigs.monthly);
    }, [notificationConfigs])

    const addInput = () => {
        emailList.push("");
        setEmailList([...emailList]);
    };

    const saveConfig = async () => {
      setLoading(true);
      const payload = {
        onCaptured: captureValue,
        daily: dailyValue,
        weekly: weeklyValue,
        monthly: monthlyValue,
        emails: emailList.filter(e => !!e),
        hasDaily: dailyValue.length > 0,
        hasWeekly: weeklyValue.length > 0,
        hasMonthly: monthlyValue.length > 0,
      };
      const reference = doc(db, 'reportNotifications', companyId)

      await updateDoc(reference, payload).then(() => {
        // reload
        setNotificationConfigFetched(false)
        setMessage("Configuration successfully updated.")
      }).catch(e => {
        setErrorMessage('Could not save notification config.')
      })
      setLoading(false);
    }

    return (
      <>
      <Text size="xl" weight="semibold">
        Notifications
      </Text>
      <Text size="sm" weight="italic">
        Configure emailed reports
      </Text>
      <Divider my="sm" />
      {loading ? (
          <Loader style={{ margin: "1em auto", width: "100%" }}></Loader>
        ) : (
        <Stack style={{ padding: "1em 2em" }}>
          <Text size="lg">Emails</Text>
                {emailList.map((email, i) => (
                <Input 
                    key={i}
                    value={email}
                    size="md"
                    onChange={e => {
                        emailList[i] = e.target.value;
                        setEmailList([...emailList]);
                        }}/>
                    ))
                }
          <Button  color="cap-navy" onClick={addInput} size="md" style={{maxWidth: '50%', minWidth: '25%'}}>Add email</Button>
          <MultiSelect 
            label="Reports on capture"
            value={captureValue} 
            onChange={setCaptureValue} 
            data={nonRangedReportConfigs.map((config) => {
              return { value: config.id, label: config.reportShortName };
            })} />
          <MultiSelect 
            label="Daily reports"
            value={dailyValue} 
            onChange={setDailyValue} 
            data={reportConfigs.map((config) => {
              return { value: config.id, label: config.reportShortName };
            })} />
          <MultiSelect 
            label="Weekly reports"
            value={weeklyValue}
            onChange={setWeeklyValue} 
            data={reportConfigs.map((config) => {
              return { value: config.id, label: config.reportShortName };
            })} />
          <MultiSelect 
            label="Monthly reports"
            value={monthlyValue} 
            onChange={setMonthlyValue} 
            data={reportConfigs.map((config) => {
              return { value: config.id, label: config.reportShortName };
            })} />
          <Button size="md" color="cap-navy" onClick={saveConfig}>Save</Button>
        </Stack>
        )
      }
      {message && (
        <Notification
          icon={<Check size={18} />}
          color="teal"
          title="Success"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setMessage("")}
        >
          {message}
        </Notification>
      )}
      {errorMessage && (
        <Notification
          icon={<Flag size={18} />}
          color="red"
          title="Error"
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
          onClose={() => setErrorMessage("")}
        >
          {errorMessage}
        </Notification>
      )}
      </>
    );
  }
  