import { initializeApp } from "firebase/app";
import {
  // GoogleAuthProvider,
  getAuth,
  // signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  applyActionCode,
  signOut,
  // connectAuthEmulator,
} from "firebase/auth";
import {
  getFirestore,
  // query,
  // getDocs,
  // collection,
  // where,
  // addDoc,
} from "firebase/firestore";

import {
  getFunctions,
  // connectFunctionsEmulator
} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBLaUSDw45JGnsdcFJpGYYI6nWgKc-ed-w",
  authDomain: "twodata-dev.firebaseapp.com",
  projectId: "twodata-dev",
  storageBucket: "twodata-dev.appspot.com",
  messagingSenderId: "407316078017",
  appId: "1:407316078017:web:6b76d094555a8e2d056340",
  measurementId: "G-1R262ZRWJV",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app, 'europe-west3');
// connectAuthEmulator(auth, "http://localhost:9099");
// connectFunctionsEmulator(functions, "localhost", 5001);
const db = getFirestore(app);

// const googleProvider = new GoogleAuthProvider();

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    alert("Login failed. Please provide a valid username and password.");
  }
};

/**
 * UNUSED UTIL METHODS
 */

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  functions,
  // signInWithGoogle,
  logInWithEmailAndPassword,
  sendPasswordReset,
  applyActionCode,
  logout,
};
