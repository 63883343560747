import React, { useCallback, useEffect, useState } from "react";
import { getDocs } from "firebase/firestore";
// keep - might be future functionality, need to reinstall dep if needed
// import { CSVLink } from "react-csv";

import { Alert, Button, Divider, Text } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";

import { db } from "../../firebase";
import {
  useBuildQuery,
  useHandleQueryResult,
  useResetListingState,
  getWeekOldDate,
} from "./hooks";

import { DataEntryTable } from "./data-entry-table";

export function DataEntryListing({
  companyId,
  templateName,
  displayConfig,
  header,
}) {
  const [documents, setDocuments] = useState([]);
  const [doneInitialFetch, setInitialFetched] = useState(false);
  const [lastVisibleDoc, setLastVisibleDoc] = useState();
  const [paginationDone, setPaginationDone] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showTooltip, setShowTooltip] = useState(false);

  const [dates, setDates] = useState([getWeekOldDate(1), new Date()]);

  const pageSize = 20;

  const buildQuery = useBuildQuery({
    db,
    companyId,
    pageSize,
    collectionName: templateName,
  });

  const handleResults = useHandleQueryResult({
    pageSize,
    setDocuments,
    setLastVisibleDoc,
    setPaginationDone,
  });

  const reset = useResetListingState({
    setDocuments,
    setInitialFetched,
    setLastVisibleDoc,
    setPaginationDone,
  });

  // reset listing if dates changed
  useEffect(() => {
    if (dates[0] && dates[1]) {
      reset();
    }
  }, [dates]);

  // load more entries / documents
  const loadMoreEntries = useCallback(async () => {
    if (loading) return;

    setInitialFetched(true);
    setLoading(true);

    const documentSnapshots = await getDocs(buildQuery(lastVisibleDoc, dates));

    handleResults(documentSnapshots);

    setLoading(false);
  }, [loading, lastVisibleDoc, buildQuery, dates, handleResults]);

  // TODO - fix double trigger, only visible locally using Strict Mode
  useEffect(() => {
    if (!doneInitialFetch && companyId) {
      loadMoreEntries();
    }
  }, [companyId, doneInitialFetch, loadMoreEntries]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text size="lg" weight="semibold">
          {header}
        </Text>

        <div style={{ display: "inline-flex" }}>
          <DateRangePicker
            placeholder="Date range"
            amountOfMonths={1}
            style={{ width: "12em" }}
            inputFormat="MM/DD/YYYY"
            clearable={false}
            value={dates}
            onChange={setDates}
          />
          {/* MIGHT BE FUTURE FUNCTIONALITY */}
          {/* <Tooltip
            label="Download current list as CSV"
            opened={showTooltip}
            withArrow
          >
            <Button color="cap-navy"
              variant="default"
              size="md"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <CSVLink data={documents}>Download</CSVLink>
            </Button>
          </Tooltip> */}
        </div>
      </div>
      <Divider my="sm" />
      {documents && documents?.length ? (
        <DataEntryTable
          elements={documents}
          setDocuments={setDocuments}
          config={displayConfig}
          templateName={templateName}
          companyId={companyId}
        ></DataEntryTable>
      ) : (
        <Alert title="No entries found" color="yellow">
          Please select a different date range
        </Alert>
      )}
      {!paginationDone && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "2em",
          }}
        >
          <Button
            color="cap-navy"
            style={{ padding: "0 20%" }}
            onClick={loadMoreEntries}
            disabled={loading}
          >
            {documents.length ? "Load more entries" : "Load recent entries"}
          </Button>
        </div>
      )}
    </>
  );
}
