import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";

const createPaypalOrderCallable = httpsCallable(functions, "createPaypalOrder");
const capturePaypalPaymentCallable = httpsCallable(
  functions,
  "capturePaypalPayment"
);
// interface CreatePaypalOrderRequest {
//   pages: number;
//   companyId: string
// }

// interface CreatePaypalOrderResponse {
//   id: string;
//   companyId: string
// }

export const createPaypalOrder = async (credits, companyId) => {
  const request = { credits, companyId };
  const res = await createPaypalOrderCallable(request);
  return res.data;
};

export const capturePaypalPayment = async (orderId, companyId) => {
  const res = await capturePaypalPaymentCallable({ orderId, companyId });
  return res.data;
};
