import { useCallback } from "react";

import {
  collection,
  query,
  where,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore";

export function useBuildQuery({ db, companyId, pageSize, collectionName }) {
  return useCallback(
    (lastVisibleDoc, dates) => {
      // If there is a pagination index - use that instead
      if (lastVisibleDoc) {
        return query(
          collection(db, collectionName),
          where("companyId", "==", companyId),
          where("transactionDate", ">", dates[0]),
          where("transactionDate", "<", dates[1]),
          orderBy("transactionDate"),
          startAfter(lastVisibleDoc),
          limit(pageSize)
        );
      } else {
        // Use first query
        return query(
          collection(db, collectionName),
          where("companyId", "==", companyId),
          where("transactionDate", ">", dates[0]),
          where("transactionDate", "<", dates[1]),
          orderBy("transactionDate"),
          limit(pageSize)
        );
      }
    },
    [db, companyId, pageSize, collectionName]
  );
}

export function useHandleQueryResult({
  pageSize,
  setDocuments,
  setLastVisibleDoc,
  setPaginationDone,
}) {
  return useCallback(
    (documentSnapshots) => {
      let docs = [];
      documentSnapshots.forEach((doc) =>
        docs.push({ id: doc.id, ...doc.data() })
      );

      if (docs.length < pageSize) {
        setPaginationDone(true);
      } else {
        docs.splice(-1);
      }
      setDocuments((existingDocs) => existingDocs.concat(docs));

      setLastVisibleDoc(
        documentSnapshots.docs[documentSnapshots.docs.length - 2]
      );
    },
    [pageSize, setDocuments, setLastVisibleDoc, setPaginationDone]
  );
}

export function useResetListingState({
  setDocuments,
  setInitialFetched,
  setLastVisibleDoc,
  setPaginationDone,
}) {
  return useCallback(() => {
    setDocuments([]);
    setInitialFetched(false);
    setLastVisibleDoc(undefined);
    setPaginationDone(false);
  }, [setDocuments, setInitialFetched, setLastVisibleDoc, setPaginationDone]);
}
