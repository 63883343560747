import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Alert, Button, Modal } from "@mantine/core";

import { DataEntryListing } from "../components";
import { logout } from "../firebase";

import {
  TransferConfig,
  TankIssueConfig,
  BulkReceiptConfig,
  TankReadingConfig,
  RefuelConfig,
} from "../components/listings/configurations";
import { CompanyDetails } from "./company-details";

export function Dashboard({ auth }) {
  const { templateName } = useParams();

  const [companyId, setCompanyId] = useState();
  const [userId, setUserId] = useState();
  const [error, setError] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (auth) {
      if (auth.claims.admin) {
        setIsAdmin(true)
      }
      if (auth?.claims?.companyId) {
        setCompanyId(auth.claims.companyId);
        setUserId(auth.claims.user_id);
        setError(undefined);
      } else {
        setCompanyId(undefined);
        setError(
          `Onboarding incomplete. Go back to the CaptivaFuel app to complete onboarding.`
        );
      }
    } else {
      setCompanyId(undefined);
    }
  }, [auth]);

  return (
    <>{isAdmin && (
        companyId && (
          <>
            {!templateName && (
              <CompanyDetails companyId={companyId} userId={userId} />
            )}
            {templateName === "capturedFuelTankTransfer" && (
              <DataEntryListing
                companyId={companyId}
                displayConfig={TransferConfig}
                templateName="capturedFuelTankTransfer"
                header="Tank to tank transfers"
              />
            )}
            {templateName === "capturedTankLevelReading" && (
              <DataEntryListing
                companyId={companyId}
                displayConfig={TankReadingConfig}
                templateName="capturedTankLevelReading"
                header="Tank readings"
              />
            )}
            {templateName === "capturedFuelTankIssue" && (
              <DataEntryListing
                companyId={companyId}
                displayConfig={TankIssueConfig}
                templateName="capturedFuelTankIssue"
                header="Onsite tank issues"
              />
            )}
            {templateName === "capturedBulkFuelReceived" && (
              <DataEntryListing
                companyId={companyId}
                displayConfig={BulkReceiptConfig}
                templateName="capturedBulkFuelReceived"
                header="Bulk receipts"
              />
            )}
            {templateName === "capturedRefuel" && (
              <DataEntryListing
                companyId={companyId}
                displayConfig={RefuelConfig}
                templateName="capturedRefuel"
                header="Onroad refuels"
              />
            )}
          </>
        )
      )}
      {userId && !isAdmin && (
        <Modal
          opened={true}
          withCloseButton={false}
          onClose={() => {}}
          title="Permission required"
        >
          Please ask another Admin to upgrade your account.
          <Button
              onClick={logout}
              size="md"
              color="cap-purple"
              style={{marginTop: '1em'}}
            >
              Logout
            </Button>
        </Modal>
        // TODO ?give option to email request to Admin?
      )}
      {error && (
        <Alert title="Profile incomplete" color="red">
          {error}
        </Alert>
      )}
    </>
  );
}
