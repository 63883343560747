import { httpsCallable } from "firebase/functions";

import { functions } from "../../../firebase";

const setAsAdmin = httpsCallable(functions, "setAsAdmin");
const setAsUser = httpsCallable(functions, "setAsUser");
const deleteUserByUid = httpsCallable(functions, "deleteUserByUid");

export const upgradeToAdmin = async (uid) => {
  return setAsAdmin({ uid });
};

export const downgradeToUser = async (uid) => {
  return setAsUser({ uid });
};

export const deleteUser = async () => {
  return deleteUserByUid();
};
