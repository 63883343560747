import React, { useState } from "react";
import { Box, TextInput, Button, Group, Stack, Text, Image } from "@mantine/core";
import "./app.css";
import { sendPasswordReset } from "../firebase";
import { useNavigate } from "react-router-dom";

export function ResetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  return (
    <Box className="center" sx={{ maxWidth: 300 }} mx="auto">
      <Stack gap="1">
        <Image
          radius="md"
          src="../../captiva-mark.png"
          style={{ height: "8em", width: "8em", margin: "auto" }}
        />
        <Text size="lg">Reset Password</Text>
        <TextInput
          required
          label="Email"
          placeholder="your@email.com"
          size="md"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></TextInput>
        <Group position="center" mt="md">
          <Button
            color="cap-navy"
            size="md"
            style={{ width: "100%" }}
            type="submit"
            onClick={() => sendPasswordReset(email).then(() => navigate("/"))}
          >
            Submit
          </Button>
        </Group>
      </Stack>
    </Box>
  );
}